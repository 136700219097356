<template>
  <div class="margin-passages" v-html="html"></div>
</template>

<script>
import { getInstance } from "@/auth";
import showdown from 'showdown'

export default {

  name: 'MarkdownToHtml',

  props: {
    source: String,
    docId: String
  },
  data () {
    return {
      converter: null,
      token: null,
      documentId: '',
      text: '',
      html: ''
    }
  },
  methods: {
    textToHtml() {
      let text = this.replaceLineSpaces(this.text)
      text = this.removeSpecialLines(text)
      text = this.fixMDTables(text)
      text = this.identifyURLImage(text)
      text = this.parseImages(text)
      this.html = this.converter.makeHtml(text)
    },
    fixMDTables(text) {
      var passageResult = ''
      if (text.indexOf('| --- |') != -1) {
        const pipeRegex = new RegExp("\\|", "g")
        let lastLine = ''
        // Iterate by new lines
        text.split('\n').forEach(line => {
          if (line == '| --- |') {
            if ((lastLine.match(pipeRegex) || []).length > 2) {
              line = '| --- | --- |'
            }
          }
          lastLine = line
          passageResult += line == '|' ? '\n' : `${line}\n`
        })
      }
      return passageResult.length != 0 ? passageResult : text
    },
    replaceLineSpaces(text) {
      text = text.replace(/\\n/g, '\n')
      return text
    },
    removeSpecialLines(text) {
      // remove lines with "tags:" or "anchors:" data
      let processedText = ''
      text.split('\n').forEach(line => {
          if (line.indexOf('tag:') != 0 && line.indexOf('anchor:') != 0) {
            processedText += `${line}\n`
          }
      })
      return processedText
    },
    parseImages(text) {
      const localFiles = RegExp('\\[file:///\\S+\\]','g')
      const urls = [...new Set(text.match(localFiles))]
      urls.forEach(url => {
        let fileName = url.replace('[file:///', '').replace(']', '')
        let extension = fileName.split('.').pop()
        let fileUrl = `${process.env.VUE_APP_DB_SERVICE_API_URL}library/get_image/${this.botName}/${this.documentId}/${fileName}?token=${this.token}`
        if (extension == 'mp3' || extension == 'wav'|| extension == 'ogg') {
          text = text.replaceAll(url, `<audio src="${fileUrl}" controls></audio>`)
        } else {
          // keep in one line, to not break the markdown
          text = text.replaceAll(url, ` <div class="row justify-content-center"><div class="col-6"><a target="_blank" href="${fileUrl}" data-toggle="lightbox"><img src="${fileUrl}" class="img-fluid"></a></div></div> `)
        }
      })
      return text
    },
    identifyURLImage(text) {
      const urlRegex = RegExp('(http|ftp|https):\\/\\/[\\w-]+(\\.[\\w-]+)+([\\w.,@?^=%&amp;:/~+#-]*[\\w@?^=%&amp;/~+#-])?', 'gi')
      const urls = [...new Set(text.match(urlRegex))]
      urls.forEach( url => {
        let extension = url.split('.').pop()
        if (extension == 'jpg' || extension == 'jpeg'|| extension == 'gif' || extension == 'png' || extension == 'bmp' || extension == 'ico') {
          let imageUrl = `${url}?token=${this.token}`
          text = text.replaceAll(url, imageUrl)
        }
      })
      return text
    },
    getToken() {
      return getInstance().getTokenSilently()
    }
  },
  created() {
    this.getToken().then(token => {
      this.converter = new showdown.Converter()
      this.converter.setOption('tables', true)
      this.converter.setOption('simplifiedAutoLink', true)
      this.converter.setOption('excludeTrailingPunctuationFromURLs', true)

      this.text = this.source || ''
      this.token = token
      this.botName = this.$router.currentRoute.params.bot
      if (this.docId != undefined) {
        this.documentId = this.docId.replace('/', '-')
      }
      this.textToHtml()
    })
  },
  watch: {
    source: function() {
      this.text = this.source || ''
      this.textToHtml()
    }
  }
}
</script>
<style lang="scss">

  p img {
    width: 100%;
  }

  div.margin-passages {
    table {
      margin-bottom: 15px;
    }
    td {
      border: 1px solid #c4c4c4;
      padding: 10px 4px;
    }
    th {
      padding: 10px 8px;
      border: 1px solid #282828;
      border-left: unset;
      text-align: left;
    }
    th:first-child {
      border-left: 1px solid #282828;
    }

  }
</style>
